<template>
  <b-container class="" @click.self="onParentClick">
    <b-row no-gutters>
      <b-col cols="12">
        <div class="text-center f-24 text-bold mt-3" v-if="!hideTitle">
          {{ title || telTitle }}
        </div>
        <div class="color-grey text-center mb-3">
          {{ description || telSubTitle }}
        </div>
      </b-col>
      <b-col cols="12">
        <label for="" class="custom-label">Phone number*</label>
      </b-col>
      <b-col cols="4" class="pr-2">
        <div class="dropdown-flag">
          <div class="dropdown-select">
            <div class="flag-icon" v-html="getSVGCountry(form.code)"></div>
            <div class="ml-1">{{ form.x }}</div>
            <!-- <font-awesome-icon :icon="['fas', 'chevron-down']" v-if="!isShow" />
            <font-awesome-icon :icon="['fas', 'chevron-up']" v-else /> -->
          </div>
        </div>

        <div class="dropdown-backdrop" v-if="isShow"></div>

        <div class="dropdown-list" v-if="isShow">
          <div class="d-inline-flex f-16 w-100 mb-3">
            <div class="text-bold" style="flex: 0 1 32%">Close</div>
            <div class="" style="flex: 1">Select your country</div>
          </div>
          <div class="p-2">
            <input
              type="text"
              placeholder="Search country code"
              id="search-code"
              @keyup="handleSearch($event)"
            />
          </div>
          <div class="dropdown-list-container">
            <template v-if="countryCode.length > 0">
              <template v-for="(image, index) of countryCode">
                <div
                  v-if="index == 0"
                  class="my-3"
                  :key="image.country + 'text'"
                >
                  {{ image.country.charAt(0).toUpperCase() }}
                </div>
                <div
                  :key="image.country + 'text'"
                  class="my-3"
                  v-else-if="
                    index != 0 &&
                    countryCode[index - 1].country.charAt(0).toUpperCase() !=
                      image.country.charAt(0).toUpperCase()
                  "
                >
                  {{ image.country.charAt(0).toUpperCase() }}
                </div>
                <div
                  :key="image.country"
                  :class="[
                    'dropdown-items mb-2',
                    { isActive: image.iso == form.code }
                  ]"
                  @click="selectCountryCode(image.iso, image.code)"
                >
                  <div class="d-inline-flex align-items-center w-100">
                    <span
                      v-html="getSVGCountry(image.iso)"
                      class="list-icon"
                    ></span>

                    <span class="ml-2"> {{ image.country }}</span>
                    <span class="ml-auto"> + {{ image.code }}</span>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="text-center p-2">no data</div>
            </template>
          </div>
        </div>
      </b-col>
      <b-col cols="8">
        <InputText
          v-model="form.Telephone"
          textFloat=""
          placeholder=""
          type="number"
          name="phone number"
          size="lg"
          isRequired
          @onkeypress="isNumber($event)"
          :isValidate="isValidate"
          :v="v"
          class="f-regular"
          :oninput="`if( this.value.length > 10 )  this.value = this.value.slice(0,10)`"
          inputmode="numeric"
        />
      </b-col>
      <b-col><small class="color-grey f-13">Example: 06XXXXXXXX</small></b-col>
    </b-row>

    <div class="register-bottom">
      <b-button
        type="button"
        block
        variant="confirm-primary"
        @click="$emit('ValidateTelephone')"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        >Next</b-button
      >

      <b-button
        class="mt-2"
        block
        type="button"
        variant="outline-primary"
        v-if="
          $store.state.editPhonenumber &&
          $store.state.theme.line_layout == 'Compact'
        "
        @click="
          $router.replace('/manage'), $store.commit('setEditPhoneNumber', false)
        "
        >ยกเลิก
      </b-button>
    </div>
  </b-container>
</template>

<script>
import InputText from "@/components/input/InputText";
import CountryCode from "@/assets/json/countrycode.json";
import InputSelect from "@/components/input/InputSelect";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    description: {
      required: false,
      type: String
    },
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    isValidate: {
      required: true,
      type: Boolean
    },
    hideTitle: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    InputText,
    InputSelect
  },
  created() {},
  data() {
    return {
      isShow: false,
      telTitle: this.$store.state.theme.telephone_title,
      telSubTitle: this.$store.state.theme.telephone_subtitle,
      countryCode: CountryCode
    };
  },

  methods: {
    handleSearch(value) {
      if (!value.target.value) {
        return (this.countryCode = CountryCode);
      }
      let searchList = CountryCode.filter(
        el =>
          el.code.includes(value.target.value) ||
          el.country.toLowerCase().includes(value.target.value.toLowerCase())
      );

      this.countryCode = searchList;
    },
    showDropdown() {
      this.isShow = !this.isShow;
    },
    hideDropDown() {
      this.isShow = false;
      this.countryCode = CountryCode;
    },
    selectCountryCode(val, val2) {
      this.isShow = false;
      this.form.code = val;
      this.form.x = val2;
    },
    getSVGCountry(countryName) {
      const myanmar = CountryList.findOneByCountryCode(countryName);
      if (myanmar) {
        const flagSvg = CountryFlagSvg[myanmar.code];

        return flagSvg;
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleKeypress(e) {
      console.log(e);
    },
    onParentClick() {}
  }
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep .flag-icon {
  width: 22px;
}

::v-deep .list-icon {
  width: 34px;
  height: 34px;
  display: flex;
  svg {
    border-radius: 50%;
  }
  // align-items: center;
}

.dropdown-flag {
  border: 1px solid rgba(0, 0, 0, 0.23);
  position: relative;
  height: 45px;
  // margin-top: 28px;
}
.dropdown-select {
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  width: 100%;
  > svg {
    margin-left: auto;
  }
}
.dropdown-list {
  position: fixed;
  // max-height: 250px;
  // overflow: auto;
  transition: all 0.2s;
  animation: slideIn 0.4s linear;
  background: white;
  bottom: 0;
  left: 0;
  width: 100%;
  // border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.75rem;
  height: 90vh;
  z-index: 50;
  padding: 1.25rem;
}
.dropdown-items {
  padding: 0 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: rgba(239, 239, 239, 1);
    border-radius: 8px;
  }
  &.isActive {
    background-color: rgba(239, 239, 239, 1);
    border-radius: 8px;
  }
}

.dropdown-list-container {
  overflow-y: auto;
  max-height: 90%;
  padding: 0.5rem 0;
}
.dropdown-backdrop {
  height: 100vh;
  width: 100%;
  background: #00000042;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
input#search-code {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 16px;
  height: 48px;
  width: 100%;
  &::placeholder {
    font-size: 14px;
  }
  &:focus {
    border-color: black;
  }
}
.rounded-icon {
  border-radius: 50%;
  overflow: hidden;
  width: 34px;
  height: auto;
  aspect-ratio: 1;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(1000px);
}
@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  /* 60% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  } */
  80% {
    transform: translateY(10px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}
</style>
