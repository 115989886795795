var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onParentClick.apply(null, arguments)}}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[(!_vm.hideTitle)?_c('div',{staticClass:"text-center f-24 text-bold mt-3"},[_vm._v(" "+_vm._s(_vm.title || _vm.telTitle)+" ")]):_vm._e(),_c('div',{staticClass:"color-grey text-center mb-3"},[_vm._v(" "+_vm._s(_vm.description || _vm.telSubTitle)+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"custom-label",attrs:{"for":""}},[_vm._v("Phone number*")])]),_c('b-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('div',{staticClass:"dropdown-flag"},[_c('div',{staticClass:"dropdown-select"},[_c('div',{staticClass:"flag-icon",domProps:{"innerHTML":_vm._s(_vm.getSVGCountry(_vm.form.code))}}),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.form.x))])])]),(_vm.isShow)?_c('div',{staticClass:"dropdown-backdrop"}):_vm._e(),(_vm.isShow)?_c('div',{staticClass:"dropdown-list"},[_c('div',{staticClass:"d-inline-flex f-16 w-100 mb-3"},[_c('div',{staticClass:"text-bold",staticStyle:{"flex":"0 1 32%"}},[_vm._v("Close")]),_c('div',{staticStyle:{"flex":"1"}},[_vm._v("Select your country")])]),_c('div',{staticClass:"p-2"},[_c('input',{attrs:{"type":"text","placeholder":"Search country code","id":"search-code"},on:{"keyup":function($event){return _vm.handleSearch($event)}}})]),_c('div',{staticClass:"dropdown-list-container"},[(_vm.countryCode.length > 0)?[_vm._l((_vm.countryCode),function(image,index){return [(index == 0)?_c('div',{key:image.country + 'text',staticClass:"my-3"},[_vm._v(" "+_vm._s(image.country.charAt(0).toUpperCase())+" ")]):(
                  index != 0 &&
                  _vm.countryCode[index - 1].country.charAt(0).toUpperCase() !=
                    image.country.charAt(0).toUpperCase()
                )?_c('div',{key:image.country + 'text',staticClass:"my-3"},[_vm._v(" "+_vm._s(image.country.charAt(0).toUpperCase())+" ")]):_vm._e(),_c('div',{key:image.country,class:[
                  'dropdown-items mb-2',
                  { isActive: image.iso == _vm.form.code }
                ],on:{"click":function($event){return _vm.selectCountryCode(image.iso, image.code)}}},[_c('div',{staticClass:"d-inline-flex align-items-center w-100"},[_c('span',{staticClass:"list-icon",domProps:{"innerHTML":_vm._s(_vm.getSVGCountry(image.iso))}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(image.country))]),_c('span',{staticClass:"ml-auto"},[_vm._v(" + "+_vm._s(image.code))])])])]})]:[_c('div',{staticClass:"text-center p-2"},[_vm._v("no data")])]],2)]):_vm._e()]),_c('b-col',{attrs:{"cols":"8"}},[_c('InputText',{staticClass:"f-regular",attrs:{"textFloat":"","placeholder":"","type":"number","name":"phone number","size":"lg","isRequired":"","isValidate":_vm.isValidate,"v":_vm.v,"oninput":`if( this.value.length > 10 )  this.value = this.value.slice(0,10)`,"inputmode":"numeric"},on:{"onkeypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.form.Telephone),callback:function ($$v) {_vm.$set(_vm.form, "Telephone", $$v)},expression:"form.Telephone"}})],1),_c('b-col',[_c('small',{staticClass:"color-grey f-13"},[_vm._v("Example: 06XXXXXXXX")])])],1),_c('div',{staticClass:"register-bottom"},[_c('b-button',{style:(`background-color: ${_vm.$store.state.theme.themePrimaryColor};`),attrs:{"type":"button","block":"","variant":"confirm-primary"},on:{"click":function($event){return _vm.$emit('ValidateTelephone')}}},[_vm._v("Next")]),(
        _vm.$store.state.editPhonenumber &&
        _vm.$store.state.theme.line_layout == 'Compact'
      )?_c('b-button',{staticClass:"mt-2",attrs:{"block":"","type":"button","variant":"outline-primary"},on:{"click":function($event){_vm.$router.replace('/manage'), _vm.$store.commit('setEditPhoneNumber', false)}}},[_vm._v("ยกเลิก ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }